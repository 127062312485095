@font-face {
  font-family: 'Montserrat';
  src: url(../assets/font/Montserrat-Regular.ttf);
}

.content {
  text-align: center;
  /* padding-top: 30px; */
  font-family: Montserrat;
  height: auto;
  padding: 30px  14px;
}

.content-group {
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 50px; */
}

.image-content {
  width: 360px;
  height: 426px;
  padding-top: 40px;
  padding-bottom: 20px;
}

.image-content-1{
  width: 349px;
  height: 300px;
  padding-top: 40px;
  padding-bottom: 20px;
}

h1 {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3f4144;
  margin-top: 30px;
}

h2 {
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #231f20;
}

p {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: center;
  color: #3f4144;
}

span {
  font-weight: 900;
  color: #fbb12f;
}

button {
  width: 328px;
  height: 45px;
  margin: 32.3px 0 0;
  border-radius: 5px;
  color: #fc0;
  background-color: #231f20;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fc0;
  font-family: Montserrat;
}

.loading-content {
  text-align: center;
  padding-top: 200px;
}